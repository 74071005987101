import React from "react";
// import withAuth from '../src/utils/withAuth';

// import Dashboard from "../src/dashboard/Dashboard";
// import {addUser, followUser, unfollowUser} from '../src/utils/stream';

import Home from "./home";

export default Home;

